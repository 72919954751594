import React, { Children } from 'react'
import { useRecentPosts } from '@helpers-blog'
import getImageVariant from '@components/utils/getImageVariant'

const WithRecentPosts = ({ children, limit = 3 }) => {
  const recentPosts = useRecentPosts()

  if (!recentPosts || recentPosts.length === 0) return null

  const collection = recentPosts.map(post => {
    const returnPost = {
      container: {
        variant: 'cards.interactive',
        link: {
          type: 'PAGE',
          link: post.slug
        }
      },
      text: [
        {
          text: post.category.name,
          color: 'alphaDark'
        },
        {
          text: post.title,
          variant: 'h5'
        },
        {
          text: post.excerpt
        },
        {
          text: post.author && post.author.name ? post.author.name : '',
          color: 'omegaDark'
        },
        {
          text: post.date
        },
        {
          text: `${post.timeToRead} min read`
        }
      ],
      images: [
        {
          alt: post.title,
          src: {
            childImageSharp: {
              gatsbyImageData: getImageVariant(post.thumbnail, 'vertical')
            }
          }
        }
      ],
    };

    if (post.author && post.author.thumbnail) {
      returnPost.avatar = {
        src: {
          childImageSharp: {
            gatsbyImageData: getImageVariant(post.author.thumbnail, 'small')
          }
        }
      }
    }

    return returnPost
  })

  const wrappedBlock = Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
      content: {
        text: child.props?.text || null,
        buttons: child.props?.buttons || null,
        collection: collection.splice(0, limit)
      }
    })
  )

  return wrappedBlock
}

export default WithRecentPosts

WithRecentPosts.defaultProps = {
  limit: 3
}
