import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import { Footer } from '../../components/Footer'
import { Header } from '../../components/Header'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Tabs from '@solid-ui-components/Tabs'
import Features from '@solid-ui-blocks/Features/Block05'
import Pricing from '@solid-ui-blocks/Pricing/Block01'
import Process from '@solid-ui-blocks/Features/Block03'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import FeatureTabOne from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import Blog from '@solid-ui-blocks/Blog/Block01'
import WithRecentPosts from '@solid-ui-blocks/WithRecentPosts'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'
import GatsbyConfig from '../../../gatsby-config';

const ReputationMonitoringPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo
        title='Reputation Monitoring'
        description={`Avoid negative reviews, send automated follow up customer surveys and get instant alerts when a bad review is posted. A quick win for your businesses online reputation.`}
        keywords={['review monitoring', 'online reputation management', 'google my business', 'google reviews', 'quickbooks online', 'small business']}
        siteUrl={GatsbyConfig.siteMetadata.siteUrl}
        thumbnail={{
          hero: {
            src: '/srm_acronym_logo.png'
          }
        }}
      />
      {/* Blocks */}
      <Header />
      <Container variant='full' sx={styles.heroContainer}>
        <Features content={content['hero']} />
      </Container>
      <Divider space='5' />
      <Container variant='wide' sx={styles.featuresContainer}>
        <FeatureTabOne content={content['how-we-help-stats']} reverse />
        <Divider space='4' />
        <Contact content={content['how-we-help-stats-cta']} />
      </Container>
      <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={5}>
          <Process content={content['google-review-monitoring-process']} />
        </Tabs>
      </Container>
      <Divider space='5' />
      <Pricing content={content['pricing']} />
      <Divider space='5' />
      <Contact content={content['lower-cta']} />
      <Divider space='5' />
      <Blog content={content['latest-blogs']} />
      <WithRecentPosts>
        <Blog />
      </WithRecentPosts>
      <Divider space='5' />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query reputationMonitoringBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/reputation-monitoring"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default ReputationMonitoringPage
